import React, { useState } from 'react';
import "../style/main.css";
import NavBar from '../components/NavBar';
import codingVideo from "../videos/coding.mp4";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import heroImage from "../images/hero.webp"
import Lottie from 'lottie-react';
import animationData from '../animations/scroll-down-animation.json';
import codingAnimation from '../animations/coding.json'
import AnimatedItem from '../components/AnimatedItem';
import a7dev from '../images/a7dev_logo_transparent.webp';
import imad from '../images/imad.webp';
import googlePlay from '../images/google-play-store-icon.webp';
import AndroidAppCard from '../components/AndroidAppCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faGithub, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { HERO_KEY, ABOUT_KEY, CONTACT_KEY, WORK_KEY } from '../utils/Constants.js';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Home() {

    const scriptUrl = "https://script.google.com/macros/s/AKfycbwR2qUb0cpVB9YSoeBOdtcvVdcrvZn7DqOmaj_r7fqxbb7YQHQdZ6S5D5ZoA_rm2_gZ/exec";
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [sending, setSending] = useState(false);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    const images = [
        require('../images/slider/1.webp'),
        require('../images/slider/2.webp'),
        require('../images/slider/3.webp'),
        require('../images/slider/4.webp'),
        require('../images/slider/5.webp'),
        require('../images/slider/6.webp'),
    ];

    const myApps = [
        {
            imageLink: "1.webp",
            title: "Crypto ?",
            description: "an Android app that provides information about various cryptocurrencies, including their current prices, low prices, high prices,change percentage , and a small description",
            link: "https://shorturl.at/goxPQ"
        },
        {
            imageLink: "2.webp",
            title: "WWeather",
            description: "an Android weather application that allows you to stay up to date with the latest weather conditions",
            link: "https://rb.gy/nw22v"
        },
        {
            imageLink: "3.webp",
            title: "My Pharmacy",
            description: "Easily locate the closest pharmacies to your current location or any area of your choice with our intuitive and user-friendly Android app. Whether you need a prescription filled urgently or you're planning ahead, our app ensures you always know where to go.",
            link: "https://play.google.com/store/apps/details?id=com.a7dev.pharmacy"
        },
        {
            imageLink: "4.webp",
            title: "Shufflit",
            description: "All-in-one utility app, designed to enhance organization and decision-making. With four powerful tools, Shufflit simplifies various tasks",
            link: "https://play.google.com/store/apps/details?id=com.a7dev.shufflit"
        },
        {
            imageLink: "5.webp",
            title: "Aratt",
            description: "an Android app designed for note-taking and management. This app allows users to create, edit, and delete note",
            link: "https://play.google.com/store/apps/details?id=com.a7dev.noteapp"
        },
        {
            imageLink: "6.webp",
            title: "AHDOWNV2",
            description: "The ultimate solution for the FASTEST YouTube video downloading on Android, offering seamless and high-speed downloads.",
            link: "https://www.instagram.com/p/C_WpcwztsD0/?img_index=1"
        }
    ]

    const goTo = (link) => {
        window.open(link, "_blank");
    }

    const handleNavBarClick = (sectionId) => {
        setTimeout(() => {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 100);
    }

    const handleSendMessage = (e) => {
        e.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (name === "" || email === "" || message === "") {
            toast.error("Please fill all fields");
            return;
        } else if (!emailRegex.test(email)) {
            toast.error("Please enter a valid email");
            return;
        }

        setSending(true);

        var formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("message", message);

        fetch(scriptUrl, {
            mode: "no-cors",
            method: "POST",
            body: formData,
        })
            .then((res) => {
                setSending(false);
                unfill();
                toast.success("Message Sent");
            })
            .catch((err) => {
                setSending(false);
                console.log(err);
                toast.error("error occured, try later");
            });
    };

    function unfill() {
        setName("");
        setEmail("");
        setMessage("");
    }


    return (
        <div className='home-container'>
            <ToastContainer position="bottom-right" />
            <NavBar handleClick={handleNavBarClick} />
            <div id={HERO_KEY} className="hero-section">
                <video autoPlay muted loop playsInline>
                    <source src={codingVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="overlay" />
                <div className="hero-content">
                    <div className='coding-animation'>
                        <Lottie
                            animationData={codingAnimation}
                            loop={true}
                            autoplay={true}
                        />
                    </div>
                    <img src={heroImage} alt="hero" className="hero-image" />
                    <h1 className='hero-title'>Turning ideas into mobile solutions,</h1>
                    <h1 className='hero-sub-title'> designed for the Android world.</h1>
                    <div className="slider-container">
                        <Slider {...settings}>
                            {images.map((image, index) => (
                                <div key={index} className="slide">
                                    <img src={image} alt={`Slide ${index + 1}`} />
                                </div>
                            ))}
                        </Slider>
                    </div>
                    <div className='scroll-down'>
                        <Lottie
                            animationData={animationData}
                            loop={true}
                            autoplay={true}
                        />
                    </div>
                </div>
            </div>
            <div id={ABOUT_KEY} className="about-section">
                <AnimatedItem elementClass={"section-title title-gray"} item={"About a7dev"} animation="fadeInFromTop" />
                <AnimatedItem
                    item={
                        <div className="about-content">
                            <div className="about-images">
                                <div className="about-image">
                                    <img src={a7dev} alt="logo" />
                                </div>
                                <div className="imad-image">
                                    <img src={imad} alt="logo" />
                                </div>
                            </div>
                            <div className="about-text">
                                <p>
                                    I am an experienced Android developer with a passion for crafting innovative and user-friendly mobile applications. With a solid background in Java and Kotlin, I focus on creating seamless user experiences and implementing the latest industry practices. My enthusiasm for mobile technology drives me to continually learn and adapt, allowing me to deliver high-quality applications that meet user needs and expectations.
                                </p>
                            </div>
                        </div>
                    }
                    animation={"fadeInFromBottom"}
                />
                <AnimatedItem item={
                    <div onClick={(e) => { e.preventDefault(); goTo("https://play.google.com/store/apps/dev?id=7125576452334487957&hl=en") }} className='google-play-btn'>
                        <img src={googlePlay} alt="google play" />
                        <p>See our Google play store</p>
                    </div>
                } animation="slideInFromLeft" />
            </div>
            <div id={WORK_KEY} className="work-section">
                <AnimatedItem item={"Our work"} elementClass={"section-title title-white"} animation="fadeInFromTop" />
                <div className="work-section-content">
                    {myApps.map((app, index) => (
                        <AnimatedItem
                            key={index}
                            item={<AndroidAppCard
                                image={app.imageLink}
                                title={app.title}
                                description={app.description}
                                link={app.link}
                            />}
                            animation={index % 2 === 0 ? "slideInFromLeft" : "slideInFromRight"}
                        />
                    ))}
                </div>
            </div>
            <div id={CONTACT_KEY} className="contact-us-section">
                <AnimatedItem item={"Contact Us"} elementClass={"section-title title-gray"} animation="popIn" />

                <AnimatedItem
                    className="animated-form"
                    item={
                        <form>
                            <div className="name-and-email">
                                <input name='name' value={name} onChange={(e) => { setName(e.target.value) }} type="text" placeholder='Your name' className="name" />
                                <input name='email' value={email} onChange={(e) => { setEmail(e.target.value) }} type="email" placeholder='Your email' className="email" />
                            </div>
                            <div className="message">
                                <textarea name='message' placeholder='Your message' value={message} onChange={(e) => { setMessage(e.target.value) }}></textarea>
                            </div>
                            <div className={sending ? "send-button send-disabled" : "send-button"}>
                                <button onClick={handleSendMessage}>{sending ? "Sending ..." : "Send"}</button>
                            </div>
                        </form>
                    }
                    animation={"fadeInFromRight"} />

            </div>
            <div className="footer">

                <AnimatedItem item={
                    <div className="footer-images">
                        <div className="about-images">
                            <div className="about-image bordered">
                                <img src={a7dev} alt="logo" />
                            </div>
                            <div className="imad-image">
                                <img src={imad} alt="logo" />
                            </div>
                        </div>

                        <h1>A7DEV by Imad AHDDAD</h1>
                    </div>
                } animation="slideInFromLeft" />

                <AnimatedItem item={
                    <div className="footer-contact">
                        <h1>contact</h1>
                        <div className="contact-element">
                            <FontAwesomeIcon className='contact-icon' icon={faMailBulk} />
                            <a href="mailto:imadahddaddev@gmail.com" target="_blank" rel="noopener noreferrer">imadahddaddev@gmail.com</a>
                        </div>
                        <div className="contact-element">
                            <FontAwesomeIcon className='contact-icon' icon={faPhone} />
                            <a href="tel:+212617463201" target="_blank" rel="noopener noreferrer">+212 617 463 201</a>
                        </div>
                        <div className="contact-element">
                            <FontAwesomeIcon className='contact-icon' icon={faLinkedin} />
                            <a href="https://www.linkedin.com/in/imad-ahddad-a2b5b7236/" target="_blank" rel="noopener noreferrer">Imad AHDDAD</a>
                        </div>
                    </div>
                } animation="slideInFromTop" />

                <AnimatedItem item={
                    <div className="footer-contact">
                        <h1>Address</h1>
                        <p>
                            Biougra,<br />
                            Chtouka Ait Baha, <br />
                            Morocco
                        </p>
                    </div>
                } animation="slideInFromLeft" />

            </div>
            <div className="social">
                <FontAwesomeIcon onClick={(e) => { e.preventDefault(); goTo("https://github.com/imad-AHDDAD/") }} className='social-icon' icon={faGithub} />
                <FontAwesomeIcon onClick={(e) => { e.preventDefault(); goTo("https://www.instagram.com/a7dev_/") }} className='social-icon' icon={faInstagram} />
                <FontAwesomeIcon onClick={(e) => { e.preventDefault(); goTo("https://web.facebook.com/imadahddad") }} className='social-icon' icon={faFacebook} />
            </div>
        </div>
    );
}

export default Home;

//G-VZY4XXJDJ2
