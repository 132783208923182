import React from 'react'

function AndroidAppCard({ image, title, description, link }) {
  const onCardClick = () => { 
    window.open(link, "_blank");
  }
  return (
    <div onClick={(e)=>{e.preventDefault(); onCardClick()}} className='android-app-card'>
      <div className="android-app-image">
        <img src={require(`../images/slider/${image}`)} alt="app logo" />
      </div>
      <div className="android-app-info">
        <h1 className="android-app-title">{title}</h1>
        <p className="android-app-description">{description}</p>
      </div>
    </div>
  )
}

export default AndroidAppCard