import React, { useEffect, useRef, useState } from 'react';
import MovingText from 'react-moving-text';

const AnimatedItem = ({ item, animation, elementClass = "" }) => {
    const [isVisible, setIsVisible] = useState(false);
    const itemRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                    } else {
                        setIsVisible(false);
                    }
                });
            },
            { threshold: 0.1 }
        );

        const currentRef = itemRef.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    return (
        <div ref={itemRef} className={`animated-item ${isVisible ? 'visible' : ''}`} style={{ minHeight: '50px', minWidth: '100px' }}>
            {isVisible && (
                <MovingText
                    className={elementClass}
                    type={animation}
                    duration="1000ms"
                    delay="0s"
                    direction="normal"
                    timing="ease"
                    iteration="1"
                    fillMode="forwards"
                >
                    {item}
                </MovingText>
            )}
        </div>

    );
};

export default AnimatedItem;
