import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function NavbarITem({ icon, title }) {
    return (
        <span className='navbar-item'>
            <FontAwesomeIcon className='navbar-item-icon' icon={icon} />
            {title}
        </span>
    )
}

export default NavbarITem